
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpService, ConfigService, ThemeService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { VehicleImageUrl } from '../_models/vehicle-image-url';
import { ResponseError } from '@shared/models';
import { Vehicle } from '../_models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class VehicleImagesUrlService extends BaseHttpService {

  private defaultImage: string;
  private audiBrand: string = 'audi';
  private vwBrand: string = 'vw';
  private vwnBrand: string = 'vwn';
  private skodaBrand: string = 'skoda';
  private bentleyBrand: string = 'bentley';
  private seatBrand: string = 'seat';
  private lamboBrand: string = 'lamborghini';

  readonly cdnSourceBase = 'https://cubiccdn.cubictelecom.com';

  constructor(_httpClient: HttpClient, _configService: ConfigService,
    _translateService: TranslateService,
    private themeService: ThemeService) {
    super(_httpClient, _configService, _translateService);
  }

  getPhoto(vehicle: Vehicle): Observable<VehicleImageUrl | ResponseError> {
    if (vehicle.vehicleBrand === null) {
      return;
    }
    const vehiclePhoto = this.getVehiclePhotoType(vehicle);
    const vehicleImageUrl = new VehicleImageUrl();
    if (vehicle.vehicleBrand.toLocaleLowerCase() === this.audiBrand) {
      vehicleImageUrl.imageUrl = this.getAudiImageUrl(vehiclePhoto);
      return of(vehicleImageUrl);
    } else if (vehicle.vehicleBrand.toLocaleLowerCase() === this.vwBrand || vehicle.vehicleBrand.toLocaleLowerCase() === this.vwnBrand) {
      vehicleImageUrl.imageUrl = this.getVWImageUrl(vehiclePhoto);
      return of(vehicleImageUrl);

    } else if (vehicle.vehicleBrand.toLocaleLowerCase() === this.bentleyBrand) {
      vehicleImageUrl.imageUrl = this.getBentleyImageUrl(vehiclePhoto);
      return of(vehicleImageUrl);

    } else if (vehicle.vehicleBrand.toLocaleLowerCase() === this.seatBrand) {
      vehicleImageUrl.imageUrl = this.getSeatImageUrl(vehiclePhoto);

      return of(vehicleImageUrl);
    } else if (vehicle.vehicleBrand.toLocaleLowerCase() === this.lamboBrand) {
      vehicleImageUrl.imageUrl = this.getLamborghiniImageUrl(vehiclePhoto);

      return of(vehicleImageUrl);
    } else if (vehicle.vehicleBrand.toLocaleLowerCase() === this.skodaBrand) {
      return this.get<VehicleImageUrl>('/skodaVehicleImages/' + vehicle.vin + '/main').pipe(
        map(data => {
          return data;
        }));
    } else {
      vehicleImageUrl.imageUrl = this.getDefaultImageUrl();
      return of(vehicleImageUrl);
    }
  }

  private getVehiclePhotoType(vehicle: Vehicle): string {
    let vehiclePhoto = vehicle.vehiclePhotoImage;
    if (vehiclePhoto === null) {
      vehiclePhoto = vehicle.vehiclePhotoThumb;
    }
    return vehiclePhoto;
  }
  private getAudiImageUrl(photoThumb: string): any {
    let imageUrl: string;
    if (photoThumb) {
      const freeRatedCdnRoot = this.cdnSourceBase + '/audi/';
      const defaultPath = 'images/vehicles/';
      imageUrl = freeRatedCdnRoot + defaultPath + photoThumb;
    } else {
      this.defaultImage = this.themeService.assetsLocation + '/vehicle/missing-car_thumb.png';
      imageUrl = this.defaultImage;
    }
    return imageUrl;
  }

  private getVWImageUrl(photoThumb: string): any {
    let imageUrl: string;
    if (photoThumb) {
      const freeRatedCdnRoot = this.cdnSourceBase + '/volkswagen/';
      const defaultPath = 'images/vehicles/';
      imageUrl = freeRatedCdnRoot + defaultPath + photoThumb;
    } else {
      this.defaultImage = this.themeService.assetsLocation + '/vehicle/missing-car_thumb.png';
      imageUrl = this.defaultImage;
    }
    return imageUrl;
  }


  private getBentleyImageUrl(photoThumb: string): any {
    let imageUrl: string;
    if (photoThumb) {
      const freeRatedCdnRoot = this.cdnSourceBase + '/bentley/';
      const defaultPath = 'images/vehicles/';
      imageUrl = freeRatedCdnRoot + defaultPath + photoThumb;
    } else {
      this.defaultImage = this.themeService.assetsLocation + '/vehicle/missing-car_thumb.png';
      imageUrl = this.defaultImage;
    }
    return imageUrl;
  }

  private getSeatImageUrl(photoThumb: string): any {
    let imageUrl: string;
    if (photoThumb) {
      const freeRatedCdnRoot = this.cdnSourceBase + '/seat/';
      const defaultPath = 'images/vehicles/';
      imageUrl = freeRatedCdnRoot + defaultPath + photoThumb;
    } else {
      this.defaultImage = this.themeService.assetsLocation + '/vehicle/missing-car_thumb.png';
      imageUrl = this.defaultImage;
    }
    return imageUrl;
  }

  ///Gets Lamborghini image url for given filename
  private getLamborghiniImageUrl(photoThumb: string): any {
    let imageUrl: string;
    if (photoThumb) {
      const freeRatedCdnRoot = this.cdnSourceBase + '/lamborghini/';
      const defaultPath = 'images/vehicles/';
      imageUrl = freeRatedCdnRoot + defaultPath + photoThumb;
    } else {
      this.defaultImage = this.themeService.assetsLocation + '/vehicle/missing-car_thumb.png';
      imageUrl = this.defaultImage;
    }
    return imageUrl;
  }

  getDefaultImageUrl(): any {
    return this.themeService.assetsLocation + '/vehicle/missing-car_thumb.png';
  }
}
